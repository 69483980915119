import { ContainerModuleProps } from "@bluebottlecoffee/design-system/components";
import { toHTML } from "@portabletext/to-html";
import { ContainerModule as ContainerModuleSchema } from "../sanity-schema";

type ContainerModuleTransformerProps = {
  data: ContainerModuleSchema;
  lang: string;
};

export function toContainerModuleProps({
  data,
  lang,
}: ContainerModuleTransformerProps): ContainerModuleProps {
  const {
    bgColor,
    heading,
    isCentered,
    prefaceParagraph,
    supplementalParagraph,
  } = data;
  return {
    ...(bgColor && { bgColor }),
    heading: {
      level: heading.level,
      text: heading.text[lang],
    },
    ...(isCentered && { isCentered }),
    ...(prefaceParagraph && {
      prefaceParagraph: toHTML(prefaceParagraph[lang]),
    }),
    ...(supplementalParagraph && {
      supplementalParagraph: toHTML(supplementalParagraph[lang]),
    }),
  };
}
