import {
  Video as VideoProps,
  VideoResponsive,
} from "@bluebottlecoffee/design-system/components/lib/types";
import { ImageResponsive } from "../sanity-schema";
import { ImageType } from "./image";

export function toVideoProps(
  data: ImageType,
  lang: string,
  playPauseAriaLabel?: string,
): VideoProps {
  const video =
    data._type === "imageResponsive" ||
    data._type === "imageResponsiveWithCaption"
      ? data.desktop
      : data;

  const altText = video?.altText?.[lang];

  if (!video.source)
    throw new Error(
      altText
        ? `Video with alt text "${altText}" has not been selected. Either remove the alt text or select a video.`
        : "Video does not contain a source",
    );

  const version = video.source?.version;
  const src = video.source?.secure_url;
  const srcParts = src.split(`v${version}`);
  const posterPrefix = srcParts[0];
  const posterSuffix = srcParts[1].split(".")[0];
  const posterUrl = `${posterPrefix}so_0/v${version}${posterSuffix}.jpg`;
  const srcOptimized = `${srcParts[0]}/f_auto,q_auto/v${version}${srcParts[1]}`;

  return {
    description: altText ?? "",
    playPauseAriaLabel,
    poster: posterUrl,
    src: srcOptimized,
  };
}

export function toVideoResponsiveProps(
  data: ImageResponsive,
  lang: string,
  playPauseAriaLabel?: string,
): VideoResponsive {
  return {
    desktop: toVideoProps(data.desktop, lang, playPauseAriaLabel),
    ...(data.mobile && { mobile: toVideoProps(data.mobile, lang) }),
  };
}
