import { QuickShopCarouselProps } from "@bluebottlecoffee/design-system/components";
import { toHTML } from "@portabletext/to-html";
import { ShopCardPropsWithSlug, toShopCardProps } from "./shop-card";
import { ShippingCopy } from "../sanity-schema";
import { DereferencedQuickShopCarouselProps } from "../../components/QuickShopCarouselWrapper";
import { toLinkProps } from "./link";
import { ConversionCopy } from "../../pages/[region]/[lang]/product/[slug]";

export type QuickShopCarouselTransformerProps = Omit<
  QuickShopCarouselProps,
  "shopCards"
> & {
  shopCards: ShopCardPropsWithSlug[];
};

export function toQuickShopCarouselProps(
  shopcardCopy: {
    copy: ConversionCopy;
    shippingCopy: ShippingCopy;
  },
  data: DereferencedQuickShopCarouselProps,
  lang: string,
  region: string,
): QuickShopCarouselTransformerProps {
  const { shopCards: products } = data;
  const shopCardsForSale = products.filter((product) => product.forSale);
  if (shopCardsForSale.length < 3) {
    throw Error(
      "QuickShopCarousel must have at least 3 products that are for sale.",
    );
  }

  const shopCards = shopCardsForSale.map((shopCard) =>
    toShopCardProps({
      data: shopCard,
      lang,
      region,
      ...shopcardCopy,
    }),
  );

  return {
    bgColor: data.bgColor ?? undefined,
    heading: toHTML(data.heading[lang]),
    shopCards,
    cta: data.cta && toLinkProps(data.cta, region, lang),
  };
}
