import { FunctionComponent, useEffect } from "react";
import {
  ContainerModule,
  ContainerModuleProps,
} from "@bluebottlecoffee/design-system/components";

export const WhereToBuy: FunctionComponent<ContainerModuleProps> = (args) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://wtb.bio/embed.min.js";
    script.defer = true;
    const nextRoot = document.getElementById("__next");
    nextRoot.appendChild(script);
  }, []);

  return (
    <ContainerModule
      {...args}
      childrenWrapperClassName="border border-greyLight"
    >
      <div
        className="drb_embed"
        data-embed="map/bluebottlecoffee"
        data-min-height="700"
        data-max-height="900"
        data-baseurl="https://wtb.bio"
      />
    </ContainerModule>
  );
};
