import { FunctionComponent, useEffect, useState } from "react";
import {
  IconAndCopyBoxProps,
  OptionAndIntervalIds,
  QuizCalculator,
  QuizCalculatorProps,
} from "@bluebottlecoffee/design-system/components";
import {
  AddSubscriptionItem,
  OrderBig,
  VariantPriceInput,
} from "@chordcommerce/react-autonomy";
import {
  Product,
  Subscription,
  SubscriptionInterval,
  SubscriptionOption,
  Variant,
} from "@bluebottlecoffee/design-system/components/lib/types";
import { ProductQuizProjection } from "../lib/sanity/quiz-queries";
import { toProductProps } from "../lib/transformers/conversion";
import { QuizSteps, SubscriptionQuizCopy } from "./Quiz";
import { parse } from "../lib/i18n";

type WholeBeanSubscriptionRec = {
  cupsPerDay: number;
  int: SubscriptionInterval;
  subOpt: SubscriptionOption;
  timePeriod: string;
};

type IntervalAndSubOption = {
  int: SubscriptionInterval;
  subOpt: SubscriptionOption;
};

export type ProductWithRequiredSubscription = Omit<
  Product,
  "subscription" | "subscriptionOptions"
> & {
  subscription: Subscription;
  subscriptionOptions: SubscriptionOption[];
};

interface QuizCalculatorWrapperProps {
  lang: string;
  productQuiz: ProductQuizProjection;
  subscriptionQuizCopy: SubscriptionQuizCopy;
  addSubscription: (addSubItem: AddSubscriptionItem) => Promise<OrderBig>;
  onNextStep: (nextStep: QuizSteps) => void;
  useVariantPrice(input: VariantPriceInput): {
    discountPrice?: number;
  };
}

export const QuizCalculatorWrapper: FunctionComponent<
  QuizCalculatorWrapperProps
> = ({
  lang,
  productQuiz,
  subscriptionQuizCopy,
  addSubscription,
  onNextStep,
  useVariantPrice,
}) => {
  // Constants
  const {
    buttonAddToCartLabel,
    lastStep,
    product,
    productOptions,
    productSchedule,
  } = productQuiz;

  const transformedProduct = toProductProps(
    product,
    lang,
  ) as ProductWithRequiredSubscription;

  const {
    variants,
    subscription: { intervals },
    subscriptionOptions,
  } = transformedProduct;

  const {
    buttonLoadingText,
    calculatorSliderFirstDetail,
    calculatorSliderLabel,
    calculatorSliderSecondDetail,
    weRecommend,
  } = subscriptionQuizCopy;

  /** true if product hasProductionDays */
  const shouldRenderCalculator: boolean =
    productQuiz.product.hasProductionDays ||
    productQuiz.product.subscriptionType === "assortment";

  const initialInterval: SubscriptionInterval = intervals[0];
  const initialSubOption: SubscriptionOption = shouldRenderCalculator
    ? (subscriptionOptions[1] ?? subscriptionOptions[0])
    : subscriptionOptions[0];
  const initialVariant: Variant =
    variants.find((variant) => variant.sku === initialSubOption.item.sku) ??
    variants[0];
  const initialAddSubData: AddSubscriptionItem = {
    endDate: null,
    interval: initialInterval,
    quantity: initialSubOption.quantityLabel.quantity,
    sku: initialSubOption.item.sku,
  };

  /**
   * Contains subscription recs as determined by product team and recorded
   * in the following document:
   * https://docs.google.com/document/d/1PGj12EtS4XQjTOM2ydRqI0RBLQ8XBBQ1Eh_ob3YKq2E/edit?usp=sharing
   */
  const wholeBeanSubscriptionRecs: WholeBeanSubscriptionRec[] = [
    {
      cupsPerDay: 0,
      int: intervals[3] ?? initialInterval,
      subOpt: subscriptionOptions[0],
      timePeriod: "infinite days",
    },
    {
      cupsPerDay: 1,
      int: intervals[2] ?? initialInterval,
      subOpt: subscriptionOptions[1] ?? initialSubOption,
      timePeriod: "12.5 days",
    },
    {
      cupsPerDay: 2,
      int: intervals[0],
      subOpt: subscriptionOptions[1] ?? initialSubOption,
      timePeriod: "6 days",
    },
    {
      cupsPerDay: 3,
      int: intervals[0],
      subOpt: subscriptionOptions[1] ?? initialSubOption,
      timePeriod: "4 days",
    },
    {
      cupsPerDay: 4,
      int: intervals[0],
      subOpt: subscriptionOptions[2] ?? initialSubOption,
      timePeriod: "3 days",
    },
    {
      cupsPerDay: 5,
      int: intervals[0],
      subOpt: subscriptionOptions[2] ?? initialSubOption,
      timePeriod: "2.5 days",
    },
  ];

  // States
  const [activeInterval, setActiveInterval] =
    useState<SubscriptionInterval>(initialInterval);
  const [activeSubOption, setActiveSubOption] =
    useState<SubscriptionOption>(initialSubOption);
  const [activeVariant, setActiveVariant] = useState<Variant>(initialVariant);
  const [addSubItem, setAddSubItem] =
    useState<AddSubscriptionItem>(initialAddSubData);
  const [addSubLoading, setAddSubLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [recommendations, setRecommendations] = useState<IntervalAndSubOption>({
    int: initialInterval,
    subOpt: initialSubOption,
  });
  const [timePeriod, setTimePeriod] = useState<string>("12.5 days");

  // Side Effects
  useEffect(() => {
    if (shouldRenderCalculator) {
      setActiveInterval(recommendations.int);
      setActiveSubOption(recommendations.subOpt);
      setAddSubItem({
        ...addSubItem,
        interval: recommendations.int,
        quantity: recommendations.subOpt.quantityLabel.quantity,
      });
    }
  }, [recommendations]);

  // Functions
  const getRecommendationCopy = (
    recs: IntervalAndSubOption,
  ): QuizCalculatorProps["resultIconCopy"] => [
    { text: recs.subOpt.quantityLabel.label },
    { text: recs.int.presentation },
  ];

  const getSubOptionFromId = (subOptionId: string): SubscriptionOption =>
    subscriptionOptions.find((opt) => opt._id === subOptionId) ??
    subscriptionOptions[0];

  const getVariantFromSubOptionId = (subOptionId: string): Variant => {
    const subOption: SubscriptionOption = getSubOptionFromId(subOptionId);
    const variant: Variant =
      variants.find((v) => v.sku === subOption.item?.sku) ?? variants[0];
    return variant;
  };

  const getIntervalFromIntervalId = (
    intervalId: string,
  ): SubscriptionInterval =>
    intervals.find((int) => int._id === intervalId) ?? intervals[0];

  /**
   * takes new data on change and sets states for activeVariant, activeSubOption,
   * activeInterval, addSubItem, and clears errorMessage.
   * Note that this intentionally does NOT update the slider or recommendations states.
   */
  const handleSelectorChange = (
    dataIdsUpdate: Partial<OptionAndIntervalIds>,
  ): void => {
    let newData: AddSubscriptionItem = addSubItem;

    if (dataIdsUpdate.optionId) {
      setActiveVariant(getVariantFromSubOptionId(dataIdsUpdate.optionId));

      const subOption = getSubOptionFromId(dataIdsUpdate.optionId);
      setActiveSubOption(subOption);

      newData = {
        ...addSubItem,
        sku: subOption.item.sku,
        quantity: subOption.quantityLabel.quantity,
      };
    }

    if (dataIdsUpdate.intervalId) {
      setActiveInterval(getIntervalFromIntervalId(dataIdsUpdate.intervalId));

      const interval = getIntervalFromIntervalId(dataIdsUpdate.intervalId);
      newData = {
        ...addSubItem,
        interval,
      };
    }

    setAddSubItem(newData);
    setErrorMessage(null);
  };

  /**
   * sets timePeriod and recommendations based on slider value
   */
  const handleSliderChange = (value: number) => {
    setTimePeriod(wholeBeanSubscriptionRecs[value].timePeriod);
    setRecommendations({
      int: wholeBeanSubscriptionRecs[value].int,
      subOpt: wholeBeanSubscriptionRecs[value].subOpt,
    });
  };

  /** call addSubscription, setErrorMessage, & call onNextStep if success */
  const handleProductQuizSubmit = async () => {
    setAddSubLoading(true);
    setErrorMessage(null);
    try {
      await addSubscription(addSubItem);
      setAddSubLoading(false);
      onNextStep(lastStep);
    } catch (error) {
      setErrorMessage(error.message);
      setAddSubLoading(false);
    }
  };

  /** gets icon name based on recommended quantity */
  const getBeanBagIconName = (): IconAndCopyBoxProps["iconName"] => {
    switch (recommendations.subOpt?.quantityLabel.quantity) {
      case 2:
        return "beanBags2";
      case 3:
        return "beanBags3";
      case 1:
      default:
        return "beanBag1";
    }
  };

  const { discountPrice } =
    useVariantPrice?.({
      sku: addSubItem.sku,
      quantity: addSubItem.quantity,
      intervalLength: addSubItem.interval.length,
      intervalUnits: addSubItem.interval.unit,
    }) ?? {};

  return (
    <QuizCalculator
      activeInterval={activeInterval}
      activeSubOption={activeSubOption}
      activeVariant={activeVariant}
      buttonText={buttonAddToCartLabel}
      buttonLoadingText={buttonLoadingText}
      discountPrice={discountPrice}
      errorMessage={errorMessage}
      formClassName="max-w-4xl place-self-center"
      frequencyLabel={productSchedule}
      iconName={getBeanBagIconName()}
      intervals={intervals}
      isSubmitLoading={addSubLoading}
      renderCalculator={shouldRenderCalculator}
      resultIconCopy={[
        { text: weRecommend },
        ...getRecommendationCopy(recommendations),
      ]}
      sizeLabel={productOptions}
      slider={{
        inputValue: 2,
        label: calculatorSliderLabel,
        max: 5,
        min: 1,
        onChange: handleSliderChange,
        sliderFirstDetailText: calculatorSliderFirstDetail,
        sliderSecondDetailText: parse(calculatorSliderSecondDetail, {
          timePeriod,
        }),
      }}
      subscriptionOptions={subscriptionOptions}
      onChange={handleSelectorChange}
      onQuizSubmit={handleProductQuizSubmit}
    />
  );
};
