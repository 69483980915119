import {
  AppDownload,
  AppPlayLinks,
  AppPlayLinksProps,
  ArticleCardCarousel,
  BulletedKeyValueListModule,
  CarouselInlineCaption,
  CategoryCarousel,
  CategoryExplore,
  CopyColumn,
  DetailPageOverview,
  EditorialFeature,
  EditorialFullBleed,
  EditorialIconBlocks,
  Hero,
  CafeProductsCarousel,
  MenuItemSelector,
  PromotionalBanner,
  Quote,
  StickyLink,
  TwoUp,
  VimeoPlayer,
  CategoryGrid,
} from "@bluebottlecoffee/design-system/components";
import { toHTML } from "@portabletext/to-html";
import { WhereToBuy } from "../components/WhereToBuy";
import { FlavorAndRoastCollection } from "../components/FlavorAndRoastCollection";
import { CafeSearch } from "../components/CafeSearch/CafeSearch";
import { OneClickSubscribeModuleWrapper } from "../components/OneClickSubscribeModuleWrapper";
import { toArticleCardCarouselProps } from "./transformers/article-card-carousel";
import {
  DereferencedCafeGroupFeature,
  cafeGroupFeatureToMenuItemSelectorProps,
} from "./transformers/cafe-group-feature-to-menu-item-selector";
import { toCarouselInlineCaptionProps } from "./transformers/carousel-inline-caption";
import { toCategoryCarouselProps } from "./transformers/category-carousel";
import { toCategoryExploreProps } from "./transformers/category-explore";
import { toCopyColumnProps } from "./transformers/copy-column";
import { toEditorialFeatureProps } from "./transformers/editorial-feature";
import { toEditorialFullBleedProps } from "./transformers/editorial-full-bleed";
import { toEditorialIconBlocksProps } from "./transformers/editorial-icon-blocks";
import { toHeroProps } from "./transformers/hero";
import { toQuoteProps } from "./transformers/quote";
import { toTwoUpProps } from "./transformers/two-up";
import { toBrewGuideDetailPageOverviewProps } from "./transformers/detail-page-overview";
import { toVimeoPlayerProps } from "./transformers/vimeo-player";
import { toBulletedKeyValueListProps } from "./transformers/bulleted-key-value-list";
import { ElementOf, isTypeByField } from "./utils";
import {
  BrewGuide,
  BrewGuidesPage,
  Cafe,
  EmailSignUp,
  HelpPage,
  Homepage,
  InstantEspresso,
  OurCoffee,
  OurStory,
  OurValues,
  Page,
  Product,
  ShippingCopy,
  ShopCollection,
  Sustainability,
} from "./sanity-schema";
import {
  DereferencedCafeLocator,
  toCafeSearchProps,
} from "./transformers/cafe-locator";
import { DereferencedCafesPage } from "../pages/[region]/[lang]/cafes";
import { toEmailSignUpProps } from "./transformers/email-sign-up";
import { EmailSignUpWrapper } from "./email-sign-up-wrapper";
import { ComponentIdInjector } from "./component-id-injector";
import { toPromotionalBannerProps } from "./transformers/promotional-banner";
import { FlavorProfileCopyTranslations } from "./transformers/flavor-profile";
import { DereferencedOneClickSubscribeModuleProps } from "./transformers/one-click-subscribe-module";
import { toLinkProps } from "./transformers/link";
import {
  DereferencedQuickShopCarouselProps,
  QuickShopCarouselWrapper,
} from "../components/QuickShopCarouselWrapper";
import { toAppDownloadProps } from "./transformers/app-download";
import { toCafeProductsProps } from "./transformers/cafe-products-carousel";
import { SanitySubscriptionPage } from "./sanity/subscription-queries";
import { QuizProjection } from "./sanity/quiz-queries";
import { Quiz } from "../components/Quiz";
import { FlavorAndRoastCarouselProjection } from "./sanity/quick-shop-carousel-queries";
import { toCategoryGrid } from "./transformers/category-grid";
import { ConversionCopy } from "../pages/[region]/[lang]/product/[slug]";
import { toContainerModuleProps } from "./transformers/container-module";
import { isFeatureEnabled } from "./utils/is-feature-enabled";
import { AriaTranslations } from "./sanity/shared";

type ModuleElementType =
  | ElementOf<BrewGuide["modules"]>
  | ElementOf<BrewGuidesPage["modules"]>
  | ElementOf<Cafe["modules"]>
  | ElementOf<DereferencedCafesPage["modules"]>
  | ElementOf<HelpPage["modules"]>
  | ElementOf<Homepage["modules"]>
  | ElementOf<InstantEspresso["modules"]>
  | ElementOf<OurCoffee["modules"]>
  | ElementOf<OurStory["modules"]>
  | ElementOf<OurValues["modules"]>
  | ElementOf<Page["modules"]>
  | ElementOf<Product["modules"]>
  | ElementOf<ShopCollection["modules"]>
  | ElementOf<SanitySubscriptionPage["modules"]>
  | ElementOf<Sustainability["modules"]>;

type RenderModuleProps = {
  module: ModuleElementType;
  region: string;
  lang: string;
  appPlayLinks?: Omit<AppPlayLinksProps, "bgColor">;
  isDark?: boolean;
  flavorProfileCopy?: FlavorProfileCopyTranslations;
  shopcardCopy?: {
    copy: ConversionCopy;
    shippingCopy: ShippingCopy;
  };
  aria?: AriaTranslations;
};

type AnchorIdType = { anchorId: string };

function moduleSelector({
  module,
  region,
  lang,
  appPlayLinks,
  isDark,
  flavorProfileCopy,
  shopcardCopy,
  aria,
}: RenderModuleProps) {
  try {
    switch (module._type) {
      case "appDownload": {
        const { appStoreAltText, googlePlayAltText } = appPlayLinks || {};
        return (
          <AppDownload
            {...toAppDownloadProps({ data: module, lang })}
            appPlayLinks={{
              ...(appStoreAltText && { appStoreAltText }),
              ...(googlePlayAltText && { googlePlayAltText }),
            }}
            isDark={isDark}
          />
        );
      }
      case "articleCardCarousel": {
        return (
          <ArticleCardCarousel
            {...toArticleCardCarouselProps(module, region, lang)}
          />
        );
      }
      case "bulletedKeyValueList": {
        return (
          <BulletedKeyValueListModule
            {...toBulletedKeyValueListProps(module, "cream", region, lang)}
          />
        );
      }
      case "cafeGroupFeature": {
        const { appPlayLinksCTA, ...cafeGroupFeatureData } = module;
        const { appStoreAltText, googlePlayAltText } = appPlayLinks;
        return (
          <MenuItemSelector
            {...cafeGroupFeatureToMenuItemSelectorProps({
              data: cafeGroupFeatureData as unknown as DereferencedCafeGroupFeature,
              lang,
              region,
            })}
            bottomComponent={
              appPlayLinksCTA ? (
                <AppPlayLinks
                  alignment="row"
                  appStoreAltText={appStoreAltText}
                  ctaText={toHTML(appPlayLinksCTA[lang])}
                  googlePlayAltText={googlePlayAltText}
                  isDark={isDark}
                />
              ) : null
            }
            isDark={isDark}
          />
        );
      }
      case "cafeLocator": {
        return (
          <CafeSearch
            {...toCafeSearchProps({
              data: module as DereferencedCafeLocator,
              isModule: true,
              lang,
              region,
            })}
          />
        );
      }
      case "cafeProductsCarousel": {
        return <CafeProductsCarousel {...toCafeProductsProps(module, lang)} />;
      }
      case "carouselInlineCaption": {
        return (
          <CarouselInlineCaption
            {...toCarouselInlineCaptionProps(module, region, lang)}
            isDark={isDark}
          />
        );
      }
      case "categoryCarousel": {
        return (
          <CategoryCarousel
            {...toCategoryCarouselProps(module, region, lang)}
            isDark={isDark}
          />
        );
      }
      case "categoryExplore": {
        return (
          <CategoryExplore
            {...toCategoryExploreProps({ data: module, region, lang })}
          />
        );
      }
      case "categoryGrid": {
        return (
          <CategoryGrid
            {...toCategoryGrid({
              data: module,
              lang,
              region,
            })}
          />
        );
      }
      case "containerModule": {
        return (
          module.mainContent === "whereToBuy" &&
          isFeatureEnabled(process.env.NEXT_PUBLIC_ENABLE_WHERE_TO_BUY) && (
            <WhereToBuy
              {...toContainerModuleProps({ data: module, lang })}
              isDark={isDark}
            />
          )
        );
      }
      case "copyColumnFreeFlow": {
        return <CopyColumn {...toCopyColumnProps(module, region, lang)} />;
      }
      case "detailPageOverview":
        return (
          <DetailPageOverview
            {...toBrewGuideDetailPageOverviewProps({
              data: module,
              region,
              lang,
            })}
            appPlayLinks={module.showAppPlayLinks ? appPlayLinks : undefined}
          />
        );
      case "editorialFeature": {
        return (
          <EditorialFeature
            {...toEditorialFeatureProps(module, region, lang)}
          />
        );
      }
      case "editorialFullBleed": {
        return (
          <EditorialFullBleed
            {...toEditorialFullBleedProps(
              module,
              region,
              lang,
              aria?.playPauseAriaLabel,
            )}
          />
        );
      }
      case "editorialIconBlock": {
        return (
          <EditorialIconBlocks
            {...toEditorialIconBlocksProps({ data: module, lang })}
            isDark={isDark}
          />
        );
      }
      case "emailSignUp": {
        const {
          signUpCopy: { genericError, invalidEmailError },
        } = module as EmailSignUp;

        return (
          <EmailSignUpWrapper
            {...{
              genericError: genericError[lang],
              invalidEmailError: invalidEmailError[lang],
              props: toEmailSignUpProps({ data: module, lang }),
            }}
          />
        );
      }
      case "flavorAndRoastCarousel": {
        return (
          <FlavorAndRoastCollection
            {...(module as unknown as FlavorAndRoastCarouselProjection)}
            lang={lang}
            region={region}
            flavorProfileCopy={flavorProfileCopy}
          />
        );
      }
      case "hero": {
        return <Hero {...toHeroProps(module, region, lang)} />;
      }
      case "oneClickSubscribeModule": {
        const moduleProps =
          module as unknown as DereferencedOneClickSubscribeModuleProps;
        return (
          <OneClickSubscribeModuleWrapper
            moduleProps={moduleProps}
            lang={lang}
          />
        );
      }
      case "promotionalBanner": {
        return (
          <PromotionalBanner {...toPromotionalBannerProps(module, lang)} />
        );
      }
      case "quickShopCarousel": {
        const moduleProps =
          module as unknown as DereferencedQuickShopCarouselProps;
        return (
          <QuickShopCarouselWrapper
            shopcardCopy={shopcardCopy}
            moduleProps={moduleProps}
            lang={lang}
            region={region}
          />
        );
      }
      case "quote":
        return <Quote {...toQuoteProps(module, lang)} isDark={isDark} />;
      case "quiz":
        return (
          <Quiz
            {...(module as unknown as QuizProjection)}
            lang={lang}
            region={region}
            shopcardCopy={shopcardCopy}
          />
        );
      case "stickyLink": {
        const { label, url: moduleUrl } = module;
        const { url } = toLinkProps(moduleUrl, region, lang);

        return <StickyLink text={label[lang]} url={url} />;
      }
      case "twoUp":
        return (
          <TwoUp
            {...toTwoUpProps(module, region, lang, flavorProfileCopy)}
            isDark={isDark}
            YSpace="md"
          />
        );
      case "vimeoPlayer":
        return <VimeoPlayer {...toVimeoPlayerProps(module)} />;
      default: {
        throw Error(`Module [${module._type}] did not have a generator.`);
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug(e);
    // eslint-disable-next-line no-console
    console.debug(module);
    throw new Error(
      `Rendering module ${module._type} failed with ${e.message},`,
    );
  }
}

export default function renderModule(props: RenderModuleProps) {
  const { module } = props;
  const anchorId: string | undefined = isTypeByField<AnchorIdType>(
    module,
    "anchorId",
  )
    ? module.anchorId
    : undefined;

  return (
    <ComponentIdInjector id={anchorId}>
      {moduleSelector(props)}
    </ComponentIdInjector>
  );
}
